
import {computed, defineComponent, onMounted, ref} from "vue";
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import TaskName from "@/views/task/TaskName.vue";

export default defineComponent({
  name: "MyTasks",
  components: {TaskName, Avatar,  DateTimeFormat, KtDatatable, Entities},
  setup() {
    const objectFilter = ref({myTasks: true, status: 'NOT_SET', completed:false, overdue : false})
    const page = computed(() => store.state.TaskModule.allPage);
    const header = [
      {
        name: 'Created',
        key: 'created',
        sortable: false
      },
      {
        name: 'Entity',
        key: 'related',
        sortable: false
      },
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Assigned',
        key: 'assigned',
        sortable: false
      },
      {
        name: 'DueDate',
        key: 'dueDate',
        sortable: false
      },
    ]
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("My Tasks",
        [
          {link: false, router: '', text: 'Tasks'},
          {link: false, router: '', text: 'My Tasks'}
        ]
      )
    })
    return {
      objectFilter,
      header,
      ...LoadFilterObjects(Actions.LOAD_ALL_TASKS, objectFilter.value, ['createdBy', 'assignee', 'team', 'caze', 'claim', 'invoice']),
      page,
      ...LoadPanel()
    }
  },
  methods: {
    discardEvent() {
      this.objectFilter.completed = false
      this.objectFilter.overdue = false
      this.updateFilterObject(this.objectFilter);
    },
    searchEvent() {
      if (this.objectFilter.completed || this.objectFilter.overdue ) {
        this.objectFilter.status = '';
      }else {
        this.objectFilter.status = 'NOT_SET'
      }
      this.updateFilterObject(this.objectFilter)
    },
  }
})
